li {
    margin: 15px 0 15px 0;
}

h3 {
    margin: 10px;
}

.para{
    text-align: justify;
}